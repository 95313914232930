*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Cairo', sans-serif;
  /* transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in; */
}

/* equivalent to dir="auto" */
input,textarea{unicode-bidi: plaintext}  

/* .boxForm .MuiTextField-root label{ */
.MuiTextField-root label{
  left: unset;
  right: 28px;
  transform-origin: top right;
} 

/* .boxForm .MuiTextField-root legend{ */
.MuiTextField-root legend{
  text-align: right;
}

.MuiFormHelperText-root{
  text-align: right !important;
}
/* start loading btn */
.loadingBtn{
  padding-right: 0 !important;
}

.loadingBtn .MuiLoadingButton-loadingIndicator {
  left: 10px !important;
}

.loadingBtn .btnLogo{
  margin-left: 8px;
  margin-top: 2px
}
/* end loading btn */

.appAlert .MuiAlert-icon{
  margin-right: 0px;
  padding: 12px 0px 7px 7px;
}

.MuiTooltip-tooltip{
  font-size: 0.7475rem !important;
}

.MuiInputBase-root.Mui-focused{
  right: 0 !important;
}

.terms{ padding-top: 30px; height: 100vh;}
.terms > header, .terms > section, .terms > footer{margin-bottom: 25px}
.terms > section > h2{margin-bottom: 10px;}